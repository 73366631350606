.our-mission-section {
  width: 1200px;
  margin: 0 auto;
  margin-top: 5em;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .our-mission-section {
    width: 1900px;
  }
}

@media screen and (max-width: 500px) {
  .our-mission-section {
    width: 100%;
    padding: 0 1em;
  }
}
