.our-blogs-section {
  width: 1285px;
  margin: 0 auto;
  margin-top: 5em;
}

.blogs-list {
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.blog-card {
  width: 30%;
  min-height: auto;
  position: relative;
  background: #ebebeb;
  cursor: pointer;
  margin: 0 5px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.blog-card:hover {
  background: linear-gradient(
    to bottom,
    var(--light-bronz) 70%,
    var(--dark-bronz) 95%
  );
  color: var(--white);
}
.blog-info {
  padding: 1.5em 2em;
}

.blog-info h3 {
  font-size: 23px;
  font-family: var(--font-text);
  letter-spacing: 1px;
}

.blog-info p {
  font-family: var(--font-primary);
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 10px;
  text-align: justify;
}

.blog-image {
  width: 100%;
  height: 280px;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .our-blogs-section {
    width: 2066px;
  }
  .blog-info {
    padding: 2em;
  }
  .blog-info h3 {
    font-size: 35px;
  }
  .blog-info p {
    font-size: 27px;
  }
}

@media screen and (max-width: 500px) {
  .our-blogs-section {
    width: 100%;
  }
  .blog-card {
    width: 45%;
    margin: 5px 5px;
  }
  .blog-image {
    height: 200px;
  }
  .blog-info h3 {
    font-size: 20px;
  }
  .blog-info p {
    font-size: 12px;
  }
  .blog-info {
    padding: 20px;
  }
}
