.glimpse-section {
  width: 100%;
  height: 85vh;
  position: relative;
  margin: 0;
  margin-top: 5em;
  margin-left: -2px;
}

.glimpse-section h2 {
  position: absolute;
  left: 33%;
  top: 10%;
  font-size: 40px;
  margin-bottom: 50px;
  text-transform: uppercase;
  font-family: var(--font-secondary);
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--light-bronz);
  z-index: 5;
}

.slider-col img,
.slider-col video {
  max-width: 100%;
  width: 100%;
  height: 285px;
}

.slider-col {
  width: 450px !important;
  transition: all 1s ease;
  transform: scale(0.6) !important;
}

.slick-slide > div {
  margin: 15px 15px;
  margin-left: 25px;
}

.slick-current .slider-col {
  transform: scale(1.3) !important;
}

.slick-dots li button:before {
  font-size: 15px;
  line-height: 60px;
  opacity: 0.5;
  color: var(--light-bronz);
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: var(--light-bronz);
}

.col-small-2 {
  width: 50%;
  height: 100%;
  padding: 4em;
  padding-top: 6em;
  padding-left: 15em;
  background-color: #142418;
}

.glimpse-section .bronz-box {
  left: 20%;
  width: 76%;
  top: 6%;
}

.video-slider {
  position: relative;
  left: 0;
  top: -65vh;
  overflow: hidden;
}

.slider-col .play-button {
  left: 42%;
  top: 37%;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .glimpse-section h2 {
    font-size: 60px;
  }
  .glimpse-section {
    height: 60vh;
  }
  .video-slider {
    position: relative;
    left: 0;
    top: -45vh;
    overflow: hidden;
  }
  .slider-col {
    width: 650px !important;
    transform: scale(0.8) !important;
  }
  .slider-col img,
  .slider-col video {
    object-fit: cover;
    height: 450px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .glimpse-section {
    height: 65vh;
  }
  .video-slider {
    top: -50vh;
  }
  .slider-col {
    width: 500px !important;
    transform: scale(0.8) !important;
  }
  .slider-col img,
  .slider-col video {
    object-fit: cover;
    height: 350px;
  }
}

@media screen and (max-width: 1500px) and (min-width: 1300px) {
  .glimpse-section {
    height: 85vh;
  }
  .video-slider {
    top: -65vh;
  }
  .slider-col {
    width: 350px !important;
    transform: scale(0.8) !important;
  }
  .slider-col img,
  .slider-col video {
    object-fit: cover;
    /* height: 350px; */
  }
}

@media screen and (max-width: 500px) {
  .glimpse-section {
    height: 42vh;
  }
  .col-small-2 {
    width: 100%;
  }
  .slick-current .slider-col,
  .slider-col {
    transform: scale(1) !important;
    width: 100% !important;
  }
  .glimpse-section .bronz-box {
    display: none;
    height: 88%;
    left: 5%;
    width: 85%;
    top: 6%;
  }
  .slider-col img,
  .slider-col video {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  .video-slider {
    top: -27vh;
    overflow: hidden;
  }
  .glimpse-section h2 {
    left: 6%;
    top: 7%;
    font-size: 33px;
    text-align: center;
  }
  .slider-col .play-button {
    left: 39%;
    top: 31%;
  }
}
