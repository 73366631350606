.about-us-page .get-in-touch-section {
  margin-top: -20em;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .about-us-page .get-in-touch-section {
    margin-top: -30.5em;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .about-us-page .get-in-touch-section {
    margin-top: -24em;
  }
}