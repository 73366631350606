@import '~react-image-gallery/styles/css/image-gallery.css';

.project-info-section {
  width: 1200px;
  margin: 0 auto;
  margin-top: 5em;
}

.project-page-price,
.project-page-location {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: var(--font-primary);
}

.project-page-location {
  margin-top: 1em;
}

.project-page-location {
  font-size: 28px;
  margin-bottom: 3em;
}

.project-page-location svg {
  color: var(--light-bronz);
}

.project-page-price span {
  color: var(--light-bronz);
  font-family: var(--font-text);
}

.project-page-info {
  font-family: var(--font-primary);
  letter-spacing: 1px;
  line-height: 35px;
  font-size: 20px;
  margin-right: 20px;
  margin-bottom: 2em;
  text-align: justify;
}

.image-gallery-slide .image-gallery-image {
  object-fit: cover;
}

.image-gallery-thumbnails-container {
  display: flex;
  width: 100%;
}

.image-gallery-thumbnail {
  flex: 1 1 auto;
  max-width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100%;
  height: 500px;
  object-fit: cover;
}

img.single-image {
  max-height: 100%;
  height: 500px;
  width: 100%;
  object-fit: cover;
}

.image-gallery-thumbnail img {
  width: 100%;
  height: auto;
  display: block;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  outline: none;
  border: 4px solid var(--light-bronz);
}

.image-gallery-icon:hover {
  color: var(--light-bronz);
}

@media screen and (max-width: 500px) {
  .project-info-section {
    width: 100%;
    padding: 0 1em;
  }
  .project-page-price {
    font-size: 30px;
  }
}
