.our-projects-section {
  width: 1200px;
  margin: 0 auto;
  margin-top: 5em;
}

.projects-list {
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.project-card {
  width: 33.33%;
  min-height: 300px;
  position: relative;
  cursor: pointer;
}

.project-info {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 20px 35px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
  color: var(--white);
  font-family: var(--font-text);
  transition: all 0.5s ease;
}

.project-card:hover .project-info {
  visibility: visible;
  opacity: 1;
}

.project-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.project-name {
  font-size: 23px;
  font-weight: bold;
}

.project-price,
.project-location {
  margin: 5px 0;
  position: absolute;
  bottom: 38px;
  font-size: 15px;
  padding-right: 2em;
  letter-spacing: 1px;
  font-family: var(--font-primary);
}

.project-price {
  bottom: 45px;
}

.project-arrow-btn {
  position: absolute;
  right: 25px;
  bottom: 7px;
  background-color: transparent;
  color: var(--white);
  border: none;
  font-size: 60px;
  cursor: pointer;
}

.project-price span {
  color: var(--light-bronz);
  font-family: var(--font-text);
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .project-name {
    font-size: 40px;
    font-weight: 700;
  }
  .project-price,
  .project-location {
    font-size: 30px;
    bottom: 26px;
  }
  .project-price {
    bottom: 70px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .project-name {
    font-size: 35px;
    font-weight: 700;
  }
  .project-location,
  .project-price {
    font-size: 20px;
  }
  .project-price {
    bottom: 58px;
  }
}

@media screen and (max-width: 1500px) and (min-width: 1300px) {
  .project-location,
  .project-price {
    font-size: 14px;
  }
  .project-arrow-btn {
    bottom: 20px;
    font-size: 40px;
  }
}

@media screen and (max-width: 500px) {
  .our-projects-section {
    width: 100%;
  }
  .project-card {
    width: 50%;
    height: 230px;
  }
  .project-name {
    font-size: 20px;
  }
  .project-price {
    bottom: 70px;
    font-size: 13px;
  }
  .project-location {
    bottom: 30px;
    font-size: 14px;
  }
  .project-info {
    padding: 15px 10px;
  }
  .project-arrow-btn {
    right: 15px;
    bottom: 22px;
    font-size: 30px;
  }
}
