.our-services-section {
  width: 1200px;
  margin: 0 auto;
  margin-top: 5em;
}

.service-list {
  width: 100%;
  display: flex;
  margin-top: 30px;
}

.service-block {
  width: 25%;
  position: relative;
  height: 300px;
  margin: 0 15px;
  padding: 1em;
  color: var(--white);
  background: linear-gradient(to bottom, var(--light-bronz), var(--dark-bronz));
}

.service-block h3 {
  font-family: var(--font-text);
  font-size: 20px;
  letter-spacing: 1px;
}

.green-block {
  background: linear-gradient(to bottom, var(--light-green), var(--dark-green));
}

.right-arrow-btn {
  position: absolute;
  bottom: 1em;
  right: 1em;
  border: none;
  background-color: transparent;
  width: 65px;
  height: 45px;
}

.right-arrow-btn img {
  max-width: 100%;
  height: 100%;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .our-services-section {
    width: 1900px;
  }
  .service-block {
    height: 500px;
  }
  .service-block h3 {
    font-size: 33px;
  }
  .right-arrow-btn {
    width: 100px;
    height: 70px;
  }
}

@media screen and (max-width: 500px) {
  .our-services-section {
    width: 100%;
  }
  .service-list {
    flex-wrap: wrap;
    justify-content: center;
  }
  .service-block {
    width: 45%;
    margin: 5px 5px;
  }
}
