.get-in-touch-section {
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(to right, var(--light-bronz), var(--dark-bronz));
  padding: 3em 0;
}

.get-in-touch-row {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.col-l-small h2,
.col-r-small h3 {
  color: var(--white);
  font-size: 35px;
  text-transform: capitalize;
  font-family: var(--font-primary);
  text-align: center;
  letter-spacing: 1px;
}

.col-r-small h3 {
  font-size: 28px;
  font-weight: 500;
  border-left: 2px solid var(--white);
  padding-left: 15px;
}

.col-r-small {
  display: flex;
  align-items: center;
}

.col-r-small .icon {
  color: var(--white);
  font-size: 45px;
  padding-right: 15px;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .get-in-touch-row {
    width: 1900px;
  }
  .col-l-small h2,
  .col-r-small h3 {
    font-size: 45px;
  }
  .col-r-small .icon {
    font-size: 70px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .get-in-touch-row {
    width: 100%;
    display: grid;
  }
  .col-r-small {
    justify-content: center;
  }
}
