.main-hero-section {
  position: relative;
  width: 100%;
  margin-top: -12em;
}

.main-hero-sec-bg,
.buildings-cut {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.buildings-cut {
  position: absolute;
  bottom: 7px;
  left: 0;
  z-index: 50;
}

@keyframes slideUp {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 43%;
  }
}

@keyframes slideUpMobile {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 44%;
  }
}

.main-hero-title {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 20%;
  left: 0;
  z-index: 20;
  animation: slideUp 2s ease-out forwards;
}

.main-hero-title h1 {
  font-size: 160px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 7rem;
  color: transparent;
  font-family: var(--menu-font);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.267));
  background: linear-gradient(to bottom, var(--white) 0%, #f0d5ba 100%);
  -webkit-background-clip: text;
  background-clip: text;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .main-hero-title {
    padding: 0 25%;
  }
  .main-hero-title h1 {
    font-size: 15em;
    line-height: 11rem;
  }
}

@media screen and (max-width: 1500px) and (min-width: 1300px) {
  .main-hero-title h1 {
    font-size: 125px;
    line-height: 6rem;
  }
}

@media screen and (max-width: 500px) {
  .main-hero-section {
    height: 650px;
    margin-top: -12em;
    overflow: hidden;
  }
  .main-hero-title {
    padding: 0 5%;
    animation: slideUpMobile 2s ease-out forwards;
  }
  .main-hero-section h1 {
    font-size: 4rem;
    line-height: 3rem;
  }
  .main-hero-sec-bg,
  .buildings-cut {
    height: 100%;
    object-fit: cover;
  }
}
