.footer {
  margin: 0 auto;
  margin-top: 5em;
  padding-bottom: 3em;
  width: 1200px;
  display: flex;
}

.col-1,
.col-2,
.col-3 {
  width: 33%;
  position: relative;
}

.col-1 div {
  position: absolute;
  bottom: 5px;
  margin-top: 10px;
  font-family: var(--font-primary);
  font-size: 20px;
  font-weight: 600;
}

.col-1 a {
  color: inherit;
}

.col-2 a {
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--menu-font);
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 18px;
  color: inherit;
}

.col-1 img {
  max-width: 100%;
  width: 65%;
}

.col-2 #navbar {
  display: grid;
}

.col-2 #navbar li:hover {
  color: var(--light-bronz);
}

.col-3 .icon {
  color: var(--light-bronz);
  font-size: 40px;
  margin-right: 15px;
  transition: all 0.5s ease;
}

.col-3 .icon:hover {
  color: var(--light-green);
}

.col-3 .f-icon {
  font-size: 47px;
  position: relative;
  top: 3px;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .footer {
    width: 1900px;
  }
  .col-1 img {
    width: 50%;
  }
  .col-1 div {
    font-size: 25px;
  }
  .col-2 a {
    font-size: 28px;
  }
  .col-3 .icon {
    font-size: 60px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 500px) {
  .footer {
    width: 100%;
    display: grid;
    padding: 0em 1em;
  }
  .col-1,
  .col-2,
  .col-3 {
    width: 100%;
  }
  .col-1 div {
    position: relative;
    margin-top: 2em;
  }
}
