.service-context {
  width: 1200px;
  margin: 0 auto;
  margin-top: 5em;
}

.service-page-title {
  font-size: 50px;
  margin-bottom: 20px;
  font-family: var(--font-primary);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--dark-green);
}

.service-page-text {
  font-family: var(--font-primary);
  letter-spacing: 1px;
  line-height: 35px;
  font-size: 20px;
  margin-right: 20px;
  margin-bottom: 5em;
  margin-top: 5em;
  text-align: justify;
}

.eb-page .service-block {
  width: 19%;
}

.eb-page .service-list {
  justify-content: center;
  margin-bottom: 5em;
}

@media screen and (max-width: 500px) {
  .service-context {
    width: 100%;
    padding: 0 1em;
  }
  .service-page-title {
    font-size: 35px;
  }
  .service-page-text {
    text-align: left;
  }

  .eb-page .service-block {
    width: 45%;
    margin: 5px 5px;
  }
}
