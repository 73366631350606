.team-page .founders-inner-sec {
  margin-top: 0;
}

.team-page .our-founders-section {
  background: none;
}

.team-page .founders-inner-sec {
  margin-top: 0 !important;
}
