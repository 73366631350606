.menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1300px;
  margin: 0 auto;
  transition: top 0.8s;
  z-index: 60;
}

@keyframes slideDown {
  from {
    top: -150px;
  }
  to {
    top: 0;
  }
}

@keyframes slideUp {
  from {
    top: 0;
  }
  to {
    top: -150px;
  }
}

.scrolled {
  position: sticky;
  width: 1200px;
  top: -150px;
  background: rgb(219 219 219 / 59%);
  backdrop-filter: blur(10px);
  padding: 25px 35px !important;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.scrolled .quality-banner {
  display: none;
}

#mainLogo {
  max-width: 100%;
  width: 80px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.267));
}

#navbar {
  position: relative;
  display: flex;
  z-index: 50;
  margin: 0 20px;
}

#navbar li {
  list-style: none;
  margin: 0 5px;
  padding: 10px;
  position: relative;
  transition: all 0.5s ease;
}

.navItem::after {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 5%;
  width: 0;
  height: 2.5px;
  background-color: var(--light-green);
  transition: width 0.3s ease;
}

.servicesNav::after {
  display: none;
}

.dropdownMenu-services {
  position: absolute;
  margin-top: 10px;
  width: 300px;
  background: linear-gradient(to bottom, var(--light-bronz), var(--dark-bronz));
  padding: 5px;
}

#navbar li:hover .navItem::after {
  width: 90%;
}

.activeNav::after {
  width: 90% !important;
}

.quality-banner {
  background: linear-gradient(to bottom, var(--light-bronz), var(--dark-bronz));
  width: 130px;
  padding: 5em 17px 10px;
  text-align: right;
  color: var(--white);
  font-family: var(--font-primary);
  letter-spacing: 1px;
  font-size: 17px;
}

.navItem,
.navItem-secondary {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--white);
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--menu-font);
  letter-spacing: 1px;
  font-size: 17px;
  transition: all 0.5s ease;
}

.navItem-secondary {
  font-size: 16px;
  font-family: var(--font-text);
  text-transform: capitalize;
}

.small-dropdown-icon {
  width: 30px;
  height: 30px;
  margin-top: -2px;
}

.headerBtns {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 50;
}

.menu .neon-btn {
  position: relative;
  top: -20px;
  padding: 20px 30px;
  font-size: 20px;
}

#mobile {
  display: none;
}

.bars,
.close {
  width: 30px;
  height: 30px;
  color: var(--white);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.267));
}

.bars.notHome,
.close.notHome {
  color: var(--light-bronz);
}

.mobileHeaderBtns {
  display: none;
}

.navItem.notHome {
  color: var(--light-green);
}

.navItem.notHome::after {
  background-color: var(--light-bronz);
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .menu {
    width: 2100px;
  }
  .scrolled {
    width: 1900px;
  }
  #mainLogo {
    width: 150px;
  }
  #navbar li {
    margin: 0 20px;
  }
  .navItem {
    font-size: 25px;
  }
  .quality-banner {
    width: 180px;
    padding: 4em 17px 10px;
    font-size: 21px;
  }
}

@media screen and (max-width: 500px) {
  .menu {
    padding: 0 1em;
    width: 100%;
  }

  nav {
    position: relative;
  }

  #mobile {
    display: block;
    width: 25%;
  }

  #navbar {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 120px;
    right: 0px;
    width: 100%;
    height: 450px;
    padding: 40px 0 0 10px;
    z-index: 999;
  }

  #navbar.activeMenu {
    display: flex;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  .headerBtns {
    display: none;
  }

  .mobileHeaderBtns {
    display: block;
    margin-left: 2em !important;
  }

  .mobileHeaderBtns .neon-btn {
    margin-top: 0;
  }
  .menu {
    display: flex;
    justify-content: space-between;
  }

  #navbar {
    order: 1;
  }

  #mainLogo {
    order: 2;
    margin: 0 auto;
    text-align: center;
  }

  .quality-banner {
    order: 3;
    letter-spacing: 2px;
    font-size: 15px;
  }

  .menu #navbar.activeMenu {
    padding-left: 3em;
    top: 7em;
    left: -1.2em;
    height: auto;
    background: linear-gradient(
      to bottom,
      var(--light-bronz),
      var(--dark-bronz)
    );
  }

  .menu #navbar.activeMenu .navItem.notHome {
    color: var(--white);
  }

  .menu #navbar.activeMenu .navItem.notHome::after {
    background-color: var(--light-green);
  }

  .navItem:after {
    background-color: #fff !important;
  }
}
