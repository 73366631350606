.contact-us-section {
  background-color: rgb(235, 235, 235);
}

.contact-us-inner-section {
  width: 1200px;
  padding-top: 5em;
  padding-bottom: 5em;
  margin: 0 auto;
}

.form-section {
  display: grid;
  justify-content: center;
}

.input-group {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 650px;
}

.input-group label {
  background-color: var(--light-bronz);
  color: var(--white);
  width: 30%;
  padding: 15px 10px;
  font-family: var(--font-primary);
  font-size: 20px;
  margin-right: 2px;
}

.input-group input {
  border: none;
  background-color: #d6d6d6;
  padding: 15px 10px;
  width: 70%;
  height: 100%;
  font-family: var(--font-primary);
  font-size: 20px;
}

.input-group input:focus-visible,
.input-group textarea:focus-visible {
  outline: none;
  border: none;
}

.input-group input:-internal-autofill-selected {
  background-color: #d6d6d6 !important;
}

.input-group textarea {
  border: none;
  background-color: #d6d6d6;
  padding: 15px 10px;
  width: 100%;
  font-family: var(--font-primary);
  font-size: 20px;
}

.error-message {
  margin-top: 10px;
  color: red;
  font-family: var(--font-primary);
  font-size: 20px;
  font-weight: 600;
}

.success-message {
  margin-top: 10px;
  font-family: var(--font-primary);
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .contact-us-inner-section {
    width: 1900px;
  }
  .input-group {
    width: 1000px;
  }
  .input-group label,
  .input-group textarea,
  .input-group input {
    padding: 20px 15px;
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .contact-us-inner-section,
  .input-group {
    width: 100%;
  }
  .input-group label {
    width: 40%;
  }
}
