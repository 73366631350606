* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/GILROY-REGULAR_0.TTF') format('truetype');
}

@font-face {
  font-family: 'GilroySemiBold';
  src: url('./assets/fonts/GILROY-SEMIBOLD_0.TTF') format('truetype');
}

@font-face {
  font-family: 'Lighthaus';
  src: url('./assets/fonts/LIGHTHAUS.OTF') format('truetype');
}

@font-face {
  font-family: 'Roadradio';
  src: url('./assets/fonts/ROADRADIO_BOLD.OTF') format('truetype');
}

:root {
  --dark-green: #112513;
  --light-green: #303d2d;
  --dark-bronz: #4f391d;
  --light-bronz: #ad8225;
  --white: #ffffff;
  --font-primary: 'Gilroy', sans-serif;
  --font-text: 'GilroySemiBold', sans-serif;
  --font-secondary: 'Lighthaus', serif;
  --menu-font: 'Roadradio', serif;
}

body {
  position: relative;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img,
button {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

::selection {
  background: var(--light-green);
  color: var(--white);
}

::-webkit-scrollbar {
  display: block;
  width: 7px;
}

::-webkit-scrollbar-track {
  background: var(--white);
}

::-webkit-scrollbar-thumb {
  background-color: var(--dark-green);
  border-right: none;
  border-left: none;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

.bronz-btn {
  color: var(--white);
  background: linear-gradient(to bottom, var(--light-bronz), var(--dark-bronz));
  border: none;
  padding: 15px 25px;
  margin-top: 20px;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: var(--font-primary);
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.5s ease;
}

.bronz-btn:hover {
  background: linear-gradient(to bottom, var(--light-green), var(--dark-green));
  transform: scale(1.05);
}

.form-submit-btn {
  color: var(--white);
  background: linear-gradient(to bottom, var(--light-green), var(--dark-green));
  border: none;
  padding: 15px 25px;
  margin: 0 auto;
  margin-top: 20px;
  width: auto;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: var(--font-primary);
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.5s ease;
}

.section-title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 20px;
  font-family: var(--font-secondary);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--dark-green);
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .section-title {
    font-size: 60px;
    margin-bottom: 45px;
  }
  .bronz-btn {
    padding: 20px 40px;
    font-size: 35px;
  }
  .form-submit-btn {
    padding: 20px 40px;
    font-size: 35px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .bronz-btn {
    font-size: 30px;
  }
  .form-submit-btn {
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .section-title {
    font-size: 25px;
  }
}
