.statistics-section {
  width: 100%;
  margin: 0 auto;
  margin-top: 5em;
  background-color: #142418;
  padding: 6em 0;
}

.statistics-row {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.col-stat h2 {
  color: var(--light-bronz);
  font-size: 60px;
  text-transform: uppercase;
  font-family: var(--font-secondary);
  font-weight: 500;
  text-align: center;
  letter-spacing: 2px;
}

.col-stat h3 {
  color: var(--white);
  font-size: 18px;
  text-transform: uppercase;
  font-family: var(--font-primary);
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
}

.col-stat {
  padding: 3em 3em;
  border-right: 2px solid #ad822542;
}

.col-stat-f {
  border-right: 2px solid #ad822542;
  padding-left: 0 !important;
}

.col-stat-l {
  border-right: 0;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .statistics-row {
    width: 1900px;
  }
  .col-stat {
    padding: 3em 6em;
  }
  .col-stat h2 {
    font-size: 80px;
  }
  .col-stat h3 {
    font-size: 25px;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 500px) {
  .statistics-row {
    width: 100%;
    display: grid;
    justify-content: center;
  }
  .col-stat {
    border: none;
  }
  .col-stat.col-stat-f {
    padding: 3em !important;
  }
}
