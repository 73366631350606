.our-founders-section {
  background-color: rgb(235, 235, 235);
}

.founders-inner-sec {
  width: 1200px;
  padding-top: 5em;
  padding-bottom: 5em;
  margin: 0 auto;
  margin-top: -20em;
}

.row {
  width: 100%;
  display: flex;
  margin-top: 40px;
}

.col-l-1 {
  width: 30%;
}

.col-l-1 img {
  max-width: 100%;
  width: 90%;
}

.col-r-1 p {
  font-family: var(--font-primary);
  letter-spacing: 1px;
  line-height: 23px;
  font-size: 15px;
  margin-right: 20px;
  margin-bottom: 10px;
  text-align: justify;
}

.col-r-1 {
  width: 65%;
}

.founder-name,
.founder-position {
  font-size: 23px;
  margin-top: 15px;
  font-family: var(--font-text);
  letter-spacing: 1px;
}

.founder-position {
  font-size: 16px;
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .founders-inner-sec {
    width: 1900px;
    margin-top: -30em;
  }
  .col-r-1 p {
    line-height: 40px;
    font-size: 27px;
  }
  .founder-name,
  .founder-position {
    font-size: 35px;
    letter-spacing: 2px;
  }
  .founder-position {
    font-size: 25px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .founders-inner-sec {
    margin-top: -24em;
  }
}

@media screen and (max-width: 500px) {
  .founders-inner-sec {
    width: 100%;
    margin-top: -15em;
  }
  .row {
    display: grid;
    padding: 0 1em;
  }
  .col-l-1,
  .col-r-1 {
    width: 100%;
  }
  .col-r-1 p {
    text-align: left;
    margin-top: 20px;
  }
}
