.about-us-section {
  width: 1200px;
  margin: 0 auto;
  margin-top: 5em;
}

.row-section {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-section .col-1 {
  width: 55%;
}

.row-section .col-2 {
  width: 45%;
}

.row-section p {
  font-family: var(--font-primary);
  letter-spacing: 1px;
  line-height: 25px;
  font-size: 16px;
  margin-right: 30px;
  margin-bottom: 10px;
  text-align: left;
}

.row-section .col-2 video,
.row-section .col-2 img {
  max-width: 100%;
  width: 100%;
}

.video-cover {
  position: relative;
  cursor: pointer;
}

.play-button {
  position: absolute;
  left: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  width: 70px;
  height: 70px;
  background-color: transparent;
  color: var(--white);
  border-radius: 50%;
  border: 4px solid var(--white);
  font-size: 30px;
  filter: drop-shadow(2px 4px 6px black);
  transition: all 0.3s ease;
}

.video-cover:hover .play-button {
  background-color: var(--white);
  color: var(--dark-green);
}

@media screen and (max-width: 2600px) and (min-width: 1930px) {
  .about-us-section {
    width: 1900px;
  }
  .row-section p {
    line-height: 40px;
    font-size: 27px;
  }
  .play-button {
    font-size: 60px;
    height: 100px;
    width: 100px;
  }
}

@media screen and (max-width: 500px) {
  .about-us-section {
    width: 100%;
    padding: 0 1em;
  }
  .row-section {
    display: grid;
  }
  .row-section .col-1,
  .row-section .col-2 {
    width: 100%;
  }
  .row-section .col-2 {
    margin-top: 20px;
  }
  .row-section p {
    text-align: left;
  }
  .play-button {
    left: 42%;
    top: 37%;
  }
}
