.download-pdf-section .form-section {
  display: flex;
  justify-content: center;
  margin-left: 28px;
}

.download-pdf-section .input-group input {
  width: 95%;
}

.download-pdf-section .input-group {
  width: 500px;
}

@media screen and (max-width: 500px) {
  .download-pdf-section .form-section {
    display: grid;
    margin-left: 0;
  }
  .download-pdf-section .input-group {
    width: 100%;
  }
}
